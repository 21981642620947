import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import md5 from 'md5';

import managerStoreModule from '../managerStoreModule';
import roleStoreModule from '../../role/roleStoreModule';

export default function useManagerModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'manager';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, managerStoreModule);
  }
  store.registerModule('role', roleStoreModule);

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(null);
  const role = computed(() => {
    if (itemLocal.value && itemLocal.value.role) {
      return itemLocal.value.role;
    }
    return null;
  });
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';

  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const loadData = () => {
    resetModal();
    if (props.item && props.item.id > 0) {
      // store.dispatch('manager/getManagerDetail', { id: props.item.id, roleId: props.item.role.id }).then(response => {
      //   if (response.data) {
      //     itemLocal.value = response.data;
      //     itemLocal.value.abilities = response.data.abilities;
      //   }
      // }).catch(error => {
      //   toastification.showToastError(error);
      // });
    }
  };

  const updateAbilitiesByRole = val => {
    if (val) {
      // store.dispatch('role/getRoleAbilities', val.id).then(response => {
      //   if (response.data) {
      //     itemLocal.value.abilities = response.data;
      //   }
      // }).catch(error => {
      //   toastification.showToastError(error);
      // });
    } else {
      itemLocal.value.abilities = [];
    }
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const data = {
      name: itemLocal.value.name,
      phone: itemLocal.value.phone,
      active: itemLocal.value.active,
      title: itemLocal.value.title,
      department: itemLocal.value.department,
    };
    if (itemLocal.value.email && itemLocal.value.email.length > 0) {
      data.email = itemLocal.value.email;
    }

    if (itemLocal.value.role && itemLocal.value.role.id > 0) {
      data.roleId = itemLocal.value.role.id;
    }

    if (itemLocal.value.apartments) {
      data.apartmentIds = itemLocal.value.apartments.map(_obj => _obj.id);
    }

    isSubmitting.value = true;

    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch('manager/updateManager', data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      data.password = md5(itemLocal.value.password);
      store
        .dispatch('manager/createManager', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  watch(role, val => {
    updateAbilitiesByRole(val);
  });
  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    loadData,
    isTingTong,
  };
}
